// import { DownOutlined, UpOutlined } from "@ant-design/icons";
// import React, { useState } from "react";

// function IndustryListView({ industryData }) {
//   const [expandedIndustry, setExpandedIndustry] = useState(null);

//   const handleToggle = (industryId) => {
//     setExpandedIndustry(expandedIndustry === industryId ? null : industryId);
//   };

//   return (
//     <ul
//       className="cateList"
//       style={{
//         listStyle: "none",
//         padding: 0,
//         overflowX: "hidden",
//         width: "100%",
//       }}
//     >
//       {industryData &&
//         industryData.map(({ industryDto, categories }) => (
//           <li key={industryDto.id} style={{}}>
//             {/* Industry Name */}
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 cursor: "pointer",
//               }}
//               onClick={() => handleToggle(industryDto.id)}
//             >
//               <a
//                 href={`https://kingsurplus.com/${industryDto.slugName}`}
//                 style={{
//                   textDecoration: "none",
//                   color: "#000",
//                   fontSize: "14px",
//                 }}
//               >
//                 {industryDto.name.trim()} ({industryDto.itemCount})
//               </a>
//               <span
//                 style={{
//                   fontSize: "14px",
//                   color: "#043289",
//                   marginRight: "20px",
//                   marginLeft: "20px",
//                 }}
//               >
//                 {expandedIndustry === industryDto.id ? (
//                   <UpOutlined />
//                 ) : (
//                   <DownOutlined />
//                 )}
//               </span>
//             </div>

//             {/* Categories Dropdown - Always Rendered */}
//             <ul
//               style={{
//                 // marginTop: "10px",
//                 // marginBottom: "10px",
//                 marginBottom: "10px",
//                 overflow:
//                   expandedIndustry === industryDto.id ? "visible" : "hidden",
//                 maxHeight: expandedIndustry === industryDto.id ? "none" : "0",
//                 transition: "max-height 0.3s ease",
//                 wordWrap: "break-word",
//                 height: expandedIndustry === industryDto.id ? "auto" : "0",
//                 overflowX: "hidden",
//                 paddingLeft: "10px",
//               }}
//             >
//               {categories && categories.length > 0 ? (
//                 categories.map((category) => (
//                   <li key={category.id}>
//                     <a
//                       href={`https://kingsurplus.com/${industryDto.slugName}/${category.slugName}`}
//                       style={{
//                         textDecoration: "none",
//                         color: "#555",
//                       }}
//                     >
//                       {category.name.trim()}
//                     </a>
//                   </li>
//                 ))
//               ) : (
//                 <li>No categories available</li>
//               )}
//             </ul>
//           </li>
//         ))}
//     </ul>
//   );
// }

// export default IndustryListView;

// import { DownOutlined, UpOutlined } from "@ant-design/icons";
// import React, { useState } from "react";
// import { FixedSizeList as List } from "react-window"; // Virtualization library
// import "./IndustryListView.css";

// function IndustryListView({ industryData }) {
//   const [expandedIndustry, setExpandedIndustry] = useState(null);

//   const handleToggle = (industryId) => {
//     setExpandedIndustry((prevState) =>
//       prevState === industryId ? null : industryId
//     );
//   };

//   const Row = ({ index, style }) => {
//     const { industryDto, categories } = industryData[index];

//     return (
//       <div style={style}>
//         <div
//           className="industry-header"
//           onClick={() => handleToggle(industryDto.id)}
//         >
//           <a
//             href={`https://kingsurplus.com/${industryDto.slugName}`}
//             className="industry-link"
//           >
//             {industryDto.name.trim()} ({industryDto.itemCount})
//           </a>
//           <span className="toggle-icon">
//             {expandedIndustry === industryDto.id ? (
//               <UpOutlined />
//             ) : (
//               <DownOutlined />
//             )}
//           </span>
//         </div>

//         {/* Categories Dropdown */}
//         <ul
//           className={`category-list ${expandedIndustry === industryDto.id ? "expanded" : ""}`}
//         >
//           {categories?.length > 0 ? (
//             categories.map((category) => (
//               <li key={category.id}>
//                 <a
//                   href={`https://kingsurplus.com/${industryDto.slugName}/${category.slugName}`}
//                   className="category-link"
//                 >
//                   {category.name.trim()}
//                 </a>
//               </li>
//             ))
//           ) : (
//             <li>No categories available</li>
//           )}
//         </ul>
//       </div>
//     );
//   };

//   return (
//     <List
//       height={600} // Adjust the height of the virtualized list to your needs
//       itemCount={industryData.length}
//       itemSize={45} // Adjust the item size to match your row height
//       width="100%"
//     >
//       {Row}
//     </List>
//   );
// }

// export default React.memo(IndustryListView);

import { DownOutlined, UpOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import { FixedSizeList as List } from "react-window";
import { createPortal } from "react-dom";
import "./IndustryListView.css";

function IndustryListView({ industryData }) {
  const [expandedIndustry, setExpandedIndustry] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const listRef = useRef(null);

  const handleToggle = (industryId, position) => {
    if (expandedIndustry === industryId) {
      setExpandedIndustry(null);
    } else {
      setExpandedIndustry(industryId);
      setDropdownPosition(position);
    }
  };

  const getItemSize = (index) => {
    const { industryDto, categories } = industryData[index];
    // Return different heights based on expanded state and categories length
    return expandedIndustry === industryDto.id
      ? 45 + (categories?.length || 1) * 30 // Expanded height
      : 45; // Default height
  };

  // Reset expanded industry when scrolling to prevent dropdown from being misplaced
  useEffect(() => {
    const handleScroll = () => {
      if (expandedIndustry !== null) {
        setExpandedIndustry(null);
      }
    };

    const listElement = listRef.current?._outerRef;
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
      return () => {
        listElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [expandedIndustry]);

  const Row = ({ index, style }) => {
    const { industryDto, categories } = industryData[index];
    const rowRef = useRef(null);

    const calculatePosition = () => {
      if (!rowRef.current) return;

      const rect = rowRef.current.getBoundingClientRect();
      return {
        top: rect.bottom,
        left: rect.left,
        width: rect.width,
      };
    };

    return (
      <div style={style} ref={rowRef}>
        <div
          className="industry-header"
          onClick={() => handleToggle(industryDto.id, calculatePosition())}
        >
          <a
            href={`https://kingsurplus.com/${industryDto.slugName}`}
            className="industry-link"
            onClick={(e) => e.stopPropagation()}
          >
            {industryDto.name.trim()} ({industryDto.itemCount})
          </a>
          <span className="toggle-icon" style={{}}>
            {expandedIndustry === industryDto.id ? (
              <UpOutlined />
            ) : (
              <DownOutlined />
            )}
          </span>
        </div>

        {/* Render dropdown with Portal */}
        {expandedIndustry === industryDto.id &&
          createPortal(
            <div
              className="dropdown-portal-container"
              style={{
                position: "fixed",
                top: dropdownPosition.top + "px",
                left: dropdownPosition.left + "px",
                width: dropdownPosition.width + "px",
                zIndex: 1000,
              }}
            >
              <ul className="category-list expanded">
                {categories?.length > 0 ? (
                  categories.map((category) => (
                    <li key={category.id}>
                      <a
                        href={`https://kingsurplus.com/${industryDto.slugName}/${category.slugName}`}
                        className="category-link"
                      >
                        {category.name.trim()}
                      </a>
                    </li>
                  ))
                ) : (
                  <li>No categories available</li>
                )}
              </ul>
            </div>,
            document.body
          )}
      </div>
    );
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (expandedIndustry !== null) {
        const dropdownElement = document.querySelector(
          ".dropdown-portal-container"
        );
        if (dropdownElement && !dropdownElement.contains(event.target)) {
          // Check if the click was on a list item
          const isListItemClick = event.target.closest(".industry-header");
          if (!isListItemClick) {
            setExpandedIndustry(null);
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [expandedIndustry]);

  return (
    <List
      ref={listRef}
      height={600}
      itemCount={industryData.length}
      itemSize={45}
      width="100%"
    >
      {Row}
    </List>
  );
}

export default React.memo(IndustryListView);
